import { nextTick } from "vue";
import { defineStore } from "pinia";
import { useProductStore } from "./product";
import { useSrfStore } from "./srf";
import { useWorkflowStore } from "./workflow";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/vue";
import type { Components } from "@/assets/docs/client.d";
import { KnownUserCompanyIds } from "@/components/product/Types";

export interface User extends Components.Schemas.G2fpModelsAccountUserDto {
  isAdmin: boolean;
  isLegal: boolean;
  isOperations: boolean;
  isLightUser: boolean;
  canSeeAllFlows: boolean;
  hasGenTwoProAccess: boolean;
  hasDocumentHubAccess: boolean;
  isUserPayingAgent: boolean;
  isUserAssetManager: boolean;
  isUserIssuer: boolean;
  isUserGentwo: boolean;
  isClient: boolean;
  isImpersonating: boolean;
  srfAccess: boolean;
  srfLiteAccess: boolean;
  isISP: boolean;
  isAlbecq: boolean;
  hasDrPricingAccessClaim: boolean;
  isOrderAcceptancePayingAgent: boolean;
  canSeeBatchPayments: boolean;
  impersonatedOriginalUser: string;
  theme: string;
}

export interface AccountState {
  user: User;
  userLogged: boolean;
  manager?: Components.Schemas.G2fpModelsViewModelsAppWebProductHubUserVm;
  company?: Components.Schemas.G2fpModelsViewModelsAppWebProductHubCompanyVm;
  issuers?: Components.Schemas.G2fpModelsViewModelsAppWebProductHubIssuerVm[] | null;
  webhookList: [];
}

export const useAccountStore = defineStore("AccountStore", {
  state: (): AccountState => ({
    user: {} as User,
    userLogged: false,
    manager: {},
    company: {},
    issuers: [],
    webhookList: [],
  }),
  actions: {
    init() {
      //check login cookie
      const user = Cookies.get("GenTwoUser");
      if (user) {
        this.setUser(JSON.parse(user));
      } else {
        this.setUser(null);
      }
    },
    setUser(userInfo: User | null | undefined) {
      if (userInfo?.email) {
        Sentry.setUser({ email: userInfo.email });
      }

      this.user = userInfo;
      this.userLogged = userInfo?.id !== undefined;

      if (this.user && userInfo && this.userLogged) {
        this.user.isAdmin = !!userInfo.roles?.includes("AdminRole");
        this.user.isLegal = !!userInfo.roles?.includes("LegalRole");
        this.user.isOperations = !!userInfo.roles?.includes("OperationsRole");
        this.user.isLightUser = !!userInfo.roles?.includes("LightUserRole");
        this.user.canSeeAllFlows = helpers.checkClaim(userInfo, "CanManageAllFlowsClaim");
        this.user.hasGenTwoProAccess = helpers.checkClaim(userInfo, "CanManageOwnFlowClaim");
        this.user.hasDocumentHubAccess = helpers.checkClaim(userInfo, "SqlAccessClaim");
        this.user.isUserPayingAgent = helpers.checkClaim(userInfo, "IsPayingAgentClaim");
        this.user.isUserAssetManager = helpers.checkClaim(userInfo, "IsAssetManagerClaim");
        this.user.isUserIssuer = helpers.checkClaim(userInfo, "IsIssuerClaim");
        this.user.isUserGentwo = helpers.checkClaim(userInfo, "IsInternalClaim");
        this.user.isImpersonating = helpers.checkClaim(userInfo, "IsImpersonating");
        this.user.srfAccess = helpers.checkClaim(userInfo, "SrfAccessClaim");
        this.user.srfLiteAccess = helpers.checkClaim(userInfo, "SrfLiteAccessClaim");
        this.user.hasDrPricingAccessClaim = helpers.checkClaim(userInfo, "DrPricingAccessClaim");
        this.user.isISP =
          userInfo.groups?.find(g => g.name?.includes("group::ISP")) !== undefined ||
          userInfo.userCompanyId === KnownUserCompanyIds.ISP;
        this.user.isClient = !!helpers.getClaim(userInfo, "CompanyIdClaim");
        this.user.isAlbecq = this.user.userCompanyId === KnownUserCompanyIds.Albecq;
        this.user.isOrderAcceptancePayingAgent =
          this.user.isUserGentwo || this.user.isUserPayingAgent;
        this.user.canSeeBatchPayments =
          this.user.isAdmin ||
          this.user.isUserGentwo ||
          (this.user.isUserPayingAgent && this.user.isISP) ||
          this.user.userCompanyId === KnownUserCompanyIds.Albecq;

        if (this.user.isImpersonating) {
          this.user.impersonatedOriginalUser = helpers.getClaim(userInfo, "OriginalUser");
        }

        Cookies.set("GenTwoUser", JSON.stringify(this.user), { expires: 31, sameSite: 'strict', secure: true });
      } else {
        Cookies.remove("GenTwoUser");
        nextTick(() => sessionStorage.clear());
      }

      this.setUserTheme(this.user?.theme || localStorage.getItem("userTheme") || "system");
    },
    setUserTheme(userTheme = "system") {
      try {
        // filter out unaccessible stylesheets
        const styleSheets = Array.from(document.styleSheets).filter(
          styleSheet => !styleSheet.href || styleSheet.href.startsWith(window.location.origin),
        );

        for (let style of styleSheets) {
          if (style instanceof CSSStyleSheet && style.cssRules) {
            for (let rule of style.cssRules) {
              if (rule instanceof CSSMediaRule && rule.media) {
                // Search for media that has (prefers-color-scheme) rule
                if (rule.media.mediaText.includes("prefers-color-scheme")) {
                  // check if original rule is "stored"
                  if (!rule.media.mediaText.includes("original")) {
                    // store original rules for system preference restore
                    if (rule.media.mediaText.includes("light")) {
                      rule.media.appendMedium("(original-prefers-color-scheme: light)");
                      // delete active rule
                      rule.media.deleteMedium("(prefers-color-scheme: light)");
                    } else if (rule.media.mediaText.includes("dark")) {
                      rule.media.appendMedium("(original-prefers-color-scheme: dark)");
                      // delete active rule
                      rule.media.deleteMedium("(prefers-color-scheme: dark)");
                    }
                  }

                  // append media rule "all" for the chosen theme
                  switch (userTheme) {
                    case "light":
                      if (rule.media.mediaText.includes("light")) {
                        rule.media.appendMedium("all");
                      } else if (
                        rule.media.mediaText.includes("dark") &&
                        rule.media.mediaText.includes("all")
                      ) {
                        rule.media.deleteMedium("all");
                      }
                      break;
                    case "dark":
                      if (rule.media.mediaText.includes("dark")) {
                        rule.media.appendMedium("all");
                      } else if (
                        rule.media.mediaText.includes("light") &&
                        rule.media.mediaText.includes("all")
                      ) {
                        rule.media.deleteMedium("all");
                      }
                      break;
                    case "system":
                    default:
                      // restore system preference
                      if (rule.media.mediaText.includes("light")) {
                        rule.media.appendMedium("(prefers-color-scheme: light)");
                        rule.media.deleteMedium("(original-prefers-color-scheme: light)");
                      } else if (rule.media.mediaText.includes("dark")) {
                        rule.media.appendMedium("(prefers-color-scheme: dark)");
                        rule.media.deleteMedium("(original-prefers-color-scheme: dark)");
                      }
                      if (rule.media.mediaText.includes("all")) {
                        rule.media.deleteMedium("all");
                      }
                      break;
                  }
                }
              }
            }
          }
        }

        if (this.user) {
          this.user.theme = userTheme;
        }
        localStorage.setItem("userTheme", userTheme);
      } catch (error) {
        console.log("Stylesheets not accessible. Failed to set theme", error);
      }
    },
    async login(credentials) {
      const tokenResponse = await this.apiClient.GetCsrfToken();

      if (tokenResponse.data && tokenResponse.data.token) {
        const loginResponse = await this.apiClient.Login(null, credentials, {
          headers: {
            [tokenResponse.data.tokenName]: tokenResponse.data.token,
          },
        });

        this.setUser(loginResponse.data);
        return loginResponse;
      }
    },
    async forgotPassword(credentials) {
      const response = await this.apiClient.ForgotPassword(null, credentials);
      return response;
    },
    async resetPassword(credentials) {
      const resetPasswordResponse = await this.apiClient.ResetPassword(
        null,
        JSON.stringify(credentials),
      );
      if (resetPasswordResponse) {
        this.setUser(resetPasswordResponse.data);
      }
      return resetPasswordResponse;
    },
    async impersonateUser(credentials) {
      const response = await this.apiClient.ImpersonateUser(null, credentials);

      this.resetAll();
      this.setUser(response.data);

      return response;
    },
    async GetLogin() {
      const tokenResponse = await this.apiClient.GetCsrfToken();

      if (tokenResponse.data && tokenResponse.data.token) {
        const loginResponse = await this.apiClient.GetLogin(null, null, {
          headers: {
            [tokenResponse.data.tokenName]: tokenResponse.data.token,
          },
        });

        this.setUser(loginResponse.data);
        Sentry.setUser({
          email: loginResponse.data.email,
          userName: loginResponse.data.userName,
          id: loginResponse.data.id,
        });
        return loginResponse;
      }
    },
    async stopImpersonateUser() {
      const stopImpersonateResponse = await this.apiClient.StopImpersonatingUser(null);
      this.resetAll();
      this.setUser(stopImpersonateResponse.data);
    },
    async logout(backend = true): Promise<boolean> {
      let result = true;
      if (backend) {
        const response = await this.apiClient.Logout();
        result = response.status === 200;
      }

      if (result) {
        this.resetAll();
        this.setUser(null);
      }

      return result;
    },
    async loadCompanyInfo(force) {
      if (!this.company?.id || force) {
        const response = await this.apiClient.GetCompanyDetails();

        this.company = response.data.company;
        this.issuers = response.data.issuers;
        this.manager = response.data.gentwoResponsibleUser;
      }
    },

    async register(payload) {
      const response = await this.apiClient.Register(null, payload);
      return response;
    },

    async confirmEmail(payload) {
      const response = await this.apiClient.ConfirmEmail(null, payload);
      return response;
    },

    async resendConfirmEmail(payload) {
      const response = await this.apiClient.ResendConfirmEmail(null, payload);
      return response;
    },

    //Dev settings
    async testWebhook(data) {
      return await this.apiClient.TestWebhook(null, JSON.stringify(data));
    },

    async saveWebhook(data) {
      return await this.apiClient.SaveWebhook(null, JSON.stringify(data));
    },
    async getWebhooks() {
      const response = await this.apiClient.GetWebhooks(null);
      this.webhookList = response.data;
      return response;
    },
    async deleteWebhook(guid) {
      return await this.apiClient.DeleteWebhook(guid);
    },
    reset() {
      this.manager = {};
      this.company = {};
      this.issuers = [];
    },
    resetAll() {
      this.reset();
      useProductStore().reset();
      useSrfStore().reset();
      useWorkflowStore().reset();
    },
  },
});

const helpers = {
  checkClaim(user, claimType, claimValue = "True") {
    const claim = user.claims.find(claim => claim.type === claimType);
    return claim && claimValue && claim.value === claimValue;
  },
  getClaim(user, claimType) {
    const claim = user.claims.find(claim => claim.type === claimType);
    return claim.value;
  },
};
