import { defineStore } from "pinia";
import { Paths } from "@/assets/docs/client.d"

export interface UserState {
  dashboard: Paths.GetUserDashboard.Responses.$200 | {};
  documentTypes: Paths.SalesDocumentTypes.Responses.$200 | {};
  digitalDocuments: Paths.IsGentwoDigitalDocument.Responses.$200 | {};
  nonDigitalDocuments: Paths.IsGentwoDigitalDocument.Responses.$200 | {};
  digitalSalesTypes: Paths.SalesDocumentTypes.Responses.$200 | {};
  issuersKYCDocuments: Paths.GetIssuers.Response.$200 | [];
}

export const useUserStore = defineStore("UserStore", {
  state: (): UserState => ({
    dashboard: {},
    documentTypes: {},
    digitalDocuments: {},
    nonDigitalDocuments: {},
    digitalSalesTypes: {},
    issuersKYCDocuments: [],
  }),
  actions: {
    async getUserImage(userId) {
      const response = await this.apiClient.GetUserImage(userId);

      if (response?.status === 200) {
        return response.data.imageSrc;
      } else {
        return null;
      }
    },
    async loadDashboard() {
      const response = await this.apiClient.GetUserDashboard();
      this.dashboard = response.data;
    },
    async loadSalesDocumentTypes() {
      const response = await this.apiClient.SalesDocumentTypes();
      this.documentTypes = response.data;
    },
    async loadDigitalSalesDocuments() {
      const response = await this.apiClient.GetLatestDocuments("true");
      this.digitalDocuments = response.data;
    },
    async loadNonDigitalSalesDocuments() {
      const response = await this.apiClient.GetLatestDocuments("false");
      this.nonDigitalDocuments = response.data;
    },
    async loadIssuersKYCDocuments(){
      const response = await this.apiClient.GetIssuersFiles();
      this.issuersKYCDocuments = response.data;
    }
  },
});
